<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="$vuetify.breakpoint.smAndDown ? '95%' : 900"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="secondary--text  "> About </span>
      </template>

      <v-card>
        <v-card-title
          :style="{
            background: appSettings.menuBarColor
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          welcome
        </v-card-title>
        <div :class="{ mobileSizeDialog: $vuetify.breakpoint.xsOnly }">
          <v-row no-gutters class="contentWrapper  secondary--text " style="">
            <v-col cols="12" md="6">
              the quick brown fox jumps over the lazy dog
            </v-col>
            <v-col cols="12" md="6">
              the quick brown fox jumps over the lazy dog
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <a
            href="http://maptivate.com.au/"
            target="_blank"
            style="text-decoration: none!important;"
          >
            <v-btn
              v-if="false"
              :color="appSettings.dialogActionButtonColor"
              text
            >
              More Information
              <font v-if="!$vuetify.breakpoint.smAndDown">About Maptivate</font>
            </v-btn>
          </a>
          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            Next
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import { mapState } from "vuex";

export default {
  name: "AboutModal",

  data() {
    return {
      dialog: false,
      links: [
        {
          url: "https://www.theschooloflostarts.com.au/our-story",
          title: "The School of Lost Arts"
        },
        {
          url: "https://livesmartlab.deakin.edu.au",
          title: `Deakin University, <br>
          Live + Smart Lab and IT Dept. Launch Pad`
        },

        {
          url: "https://tract.com.au/who-we-are/",
          title: "Tract Consultants Geelong"
        },

        {
          url: "https://www.onemap.com.au/",
          title: "OneMap"
        },

        {
          url: "https://www.cartdeco.com.au/about",
          title: "CartDeco Cartographics, Geelong"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  created() {
    eventBus.$on("loadAboutDialog", () => {
      this.dialog = true;
    });
  },
  mounted() {}
};
</script>

<style>
.mobileSizeDialog {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
.contentWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

@media only screen and (max-width: 960px) {
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 98%;
  }

  .contentWrapper {
    xmax-height: calc(100vh - 180px);
  }
}

@media only screen and (max-width: 760px) {
  .contentWrapper {
    max-height: calc(100vh - 240px);
  }
}
</style>
