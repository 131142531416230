const getters = {};
const mutations = {
  SET_maptivateSetupObject(state, newValue) {
    state.maptivateSetupObject = newValue;
  }
};

const actions = {
  async global_updateMaptivateSetupObject({ commit }, wpData) {
    commit("SET_maptivateSetupObject", wpData.maptivateSetupObject);
  }
};
const state = {
  maptivateSetupObject: {},
  appSettings: {
    map: {
      accessToken:
        "pk.eyJ1IjoibGlseWdpbGJ5IiwiYSI6ImNrdHd4Ym5lNTJvZ20yb251cXNraDJjcHcifQ.zGeYkQ3T9kDMsnUHORBXXQ",
      options: {
        style: "mapbox://styles/lilygilby/cl8novx3l000415o6qbarj9tl",
        center: [143.86582304884314, -37.576360476662174],
        maxZoom: 22,
        pitch: 30,
        bearing: 180,
        zoom: 15
      },
      bounds: [
        [143.82037117340178, -37.583883885047484], // Southwest coordinates
        [143.8701381885072, -37.54256823186398] // Northeast coordinates
      ],
      defaultSetup: {
        center: [143.86582304884314, -37.576360476662174],
        zoom: 16.75,
        pitch: 30,
        bearing: 180
      },

      disableRotationAndBearing: false
    },

    wpEditSite: "https://sh-dev.maptivateapp.com.au/wp/wp-admin/post.php?post=",

    markerIsPointer: false,
    showSiteNumbers: true,
    showSiteNumbers_Labels: true,
    showSiteNumbers_ClusterLabels: false,
    showQrCode: false,
    minZoom: { main: 1, secondary: 1, points: 1 },

    //nonClusteredDataSouces: [],
    //clusteredDataSouces: ["places", "signage"],

    useMapClustering: true,

    layerDefaults: {
      signage: {
        scaling: {
          minZoom: 16,
          opacityMinZoom: 16,
          opacityMaxZoom: 17,

          clusterOpacityMinZoom: 16,
          clusterOpacityMaxZoom: 17,
          unclusterScale: 0.85
        },

        haveToolTip: true,
        showLabels: false,
        styles: {
          unclustered_paint: { "circle-color": "#00dcff" },
          clustered_paint: { "circle-color": "#c08e60" }
        },
        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterSetup: { cluster: true, clusterMaxZoom: 17, clusterRadius: 30 },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          clustered_layer: ["has", "point_count"]
        }
      },
      places: {
        scaling: {
          minZoom: 16,
          opacityMinZoom: 17.5,
          opacityMaxZoom: 18.5,

          clusterOpacityMinZoom: 17,
          clusterOpacityMaxZoom: 18,
          unclusterScale: 1
        },

        hasPopup: true,
        showLabels: true,
        hoverState: true,
        haveToolTip: true,
        styles: {
          unclustered_paint: { "circle-color": "#ff0000" },
          clustered_paint: { "circle-color": "#354d64" }
        },

        clusterOptions: {
          useCircle: true,
          useComboIcon: false
        },
        clusterSetup: {
          cluster: false,
          clusterMaxZoom: 10,
          clusterRadius: 30,

          clusterProperties: {
            // keep separate counts for each magnitude category in a cluster
            "points-of-interest": [
              "+",
              [
                "case",
                ["in", ["get", "section_slug"], "points-of-interest"],
                1,
                0
              ]
            ],
            "wadawurrung-stories": [
              "+",
              [
                "case",
                ["in", ["get", "section_slug"], "wadawurrung-stories"],
                1,
                0
              ]
            ],
            "food-and-shopping": [
              "+",
              [
                "case",
                ["in", ["get", "section_slug"], "food-and-shopping"],
                1,
                0
              ]
            ]
          }
        },
        filter: {
          unclustered_layer: ["!", ["has", "point_count"]],
          //unclustered_layer: ["in", "section_slug","points-of-interest"],

          clustered_layer: ["has", "point_count"]
        }
      }
    },

    /*categoryLookup: {
      History: {
        icon: "history_marker",
        full: "history_full",
        logo: "history_icon",
        title: "Our Stories",
        description: "",

        color: "#626f60",
        padding: "15px"
      },

      "Prospective Families": {
        icon: "prospective_marker",
        full: "prospective_families_full",
        logo: "prospective_icon",
        title: "Our School",
        description: "",
        color: "#8e3839",
        padding: "15px"
      },

      Wayfinding: {
        icon: "wayfinding_marker",
        full: "wayfinding_full",
        logo: "wayfinding_icon",
        title: "Our Spaces",
        description: "",
        color: "#e0c381",
        padding: "6px"
      }
    },
*/
    socialIcon: {
      instagram: {
        icon: "mdi-instagram",
        color: "red lighten-3"
      },
      facebook: {
        icon: "mdi-facebook",
        color: "indigo"
      },
      twitter: {
        icon: "mdi-twitter",
        color: "#26c6da"
      },
      youtube: {
        icon: "mdi-youtube",
        color: "red"
      },
      tiktok: {
        icon: "mdi-alarm-multiple ",
        color: "black"
      },
      linkedin: {
        icon: "mdi-linkedin",
        color: "cyan darken-1"
      },
      pinterest: {
        icon: "mdi-pinterest",
        color: "pink"
      },
      generic: {
        icon: "mdi-link-variant",
        color: "green"
      }
    },

    validArSites: {
      "14": true,
      "23": true,
      "28B": true
    },
    autoOpenMoreInfo: true,
    drawWidth: 300,

    popupButtonWidth: "224px",
    drawButtonWidth: "272px",

    mediaButtonColor: "#9ec0bd", //a6caa6
    appButtonColor: "#1f3531",
    dialogActionButtonColor: "#1f3531",

    menuBarColor: "#9ec0bd",
    audioPlayColor: "1f3531",
    headingTextColor: "font-weight-regular text--darken-1",
    styles: {
      //oldMarkerColours: ["#003953", "#607d8b", "#f28cb1", "#d8db76"],
      markerColours: ["#582110", "#f4afcf", "#582110", "#961a1d"],
      //oldmarkerText: "#f6e9d8",
      markerText: "#fcfbf1"
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
