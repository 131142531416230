<template>
  <div class="text-center">
    <v-dialog
      v-model="openIntro"
      :width="$vuetify.breakpoint.smAndDown ? 500 : 900"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <font v-if="!$vuetify.breakpoint.xsOnly" class="secondary--text ">
          </font
          >Acknowledgement<font v-if="!$vuetify.breakpoint.xsOnly">
            of Country
          </font>
        </span>
      </template>

      <v-card style="overflow:hidden">
        <v-card-title
          :style="{
            background: appSettings.menuBarColor
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          Acknowledgement of Country
        </v-card-title>

        <div :class="{ mobileSizeDialog: $vuetify.breakpoint.xsOnly }">
          <v-row class="contentWrapper">
            <v-col
              cols="12"
              style="overflow:hidden;max-height:100%;margin-bottom:-50px"
              v-if="$vuetify.breakpoint.smAndDown"
            >
              <center>
                <v-img
                  class="white--text align-end  ma-5 mb-0"
                  style="overflow:hidden;max-width:20%"
                  src="/assets/landingPages/stained_glass_bunjil.png"
                >
                </v-img>
              </center>
            </v-col>

            <v-col
              cols="6"
              style="overflow:hidden;max-height:100%"
              v-if="$vuetify.breakpoint.smAndUp"
            >
              <v-img
                v-if="$vuetify.breakpoint.mdAndUp"
                class="white--text align-end  ma-5"
                xheight="300px"
                src="/assets/landingPages/stained_glass_bunjil.png"
              >
              </v-img>
            </v-col>
            <v-col md="6" cols="12" xstyle="max-height: calc(100vh - 150px);">
              <v-card-text>
                <p>the quick brown fox jumps over the lazy dog</p>
              </v-card-text>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <a
            v-if="false"
            href="http://maptivate.com.au/"
            target="_blank"
            style="text-decoration: none!important;"
          >
            <v-btn :color="appSettings.dialogActionButtonColor" text>
              More Information
            </v-btn>
          </a>

          <v-btn
            v-if="false"
            :color="appSettings.dialogActionButtonColor"
            text
            @click="loadAbout()"
          >
            About Maptivate
          </v-btn>

          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="openIntro = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  name: "AcknowledgementModal",
  components: {},
  data() {
    return {
      openIntro: false,
      properties: {
        audioDescription_soundCloudId: "987771763",
        Point_Name: "Introduction"
      }
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerList: state => state.markers.markerList,
      playingSiteName: state => state.media.playingSiteName,
      isPlaying: state => state.media.isPlaying
    }),
    isPlayingThisTrack() {
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === this.properties.Point_Name
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    loadAbout() {
      this.openIntro = false;
      eventBus.$emit("loadAboutDialog");
    },
    getAudioDetails(name) {
      let isPlayingThisTrack = false;
      let isLoadedThisTrack = false;
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === name
      ) {
        isPlayingThisTrack = true;
      }

      if (this.playingSiteName && this.playingSiteName === name) {
        isLoadedThisTrack = true;
      }
      return {
        playingSiteName: this.playingSiteName,
        isPlaying: this.isPlaying,
        isPlayingThisTrack,
        isLoadedThisTrack
      };
    },

    setSound(soundCloudId, Point_Name) {
      this.$store.dispatch("mediaSetTrackAndPlay", {
        soundCloudId,
        Point_Name
      });
    }
  },
  created() {
    /*
    if (localStorage.hasRun === "yes") {
      localStorage.hasRun = null;
      this.openIntro = false;
    } else {
      this.openIntro = true;
      localStorage.hasRun = "yes";
    }
    */
    //this.openIntro = true;

    eventBus.$on("loadIntroPopup", () => {
      this.openIntro = true;
    });
  },
  mounted() {
    setTimeout(() => {
      //this.openIntro = true;
    }, 300);
  }
};
</script>

<style></style>
