<template>
  <v-app class="my-app">
    <Layout v-if="ready" />
  </v-app>
</template>

<script>
import Layout from "./Layout.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  async mounted() {
    this.$store.dispatch("wpData_initData", { vm: this });
  },
  components: {
    Layout
  },

  data: () => ({
    ready: false
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      wpdata: state => state.wpdata.wpdata
    })
  },

  watch: {
    wpdata: function(newValue) {
      if (newValue?.categoryOrder.length) {
        this.$store.dispatch("global_updateMaptivateSetupObject", newValue);
        this.$store.dispatch("markers_updateData", newValue);
        this.$store.dispatch("filters_updateData", newValue);

        this.ready = true;
      }
    }
  },

  created() {
    window.mvAnalyticEvent = function(category, action, label, value) {
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: !isNaN(value) ? value : 1
      });
      window.gtag("event", "t_" + action + "_" + label);
    };

    document.ondblclick = function(e) {
      e.preventDefault();
    };

    document.addEventListener("gesturestart", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document.addEventListener("gesturechange", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    document.addEventListener("gestureend", function(e) {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    });

    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", documentHeight);
    window.addEventListener("orientationchange", documentHeight);
    screen.orientation.addEventListener("change", documentHeight);

    documentHeight();
  }
};
</script>
<style lang="scss">
:root {
  --doc-height: 100%;
}
body {
  touch-action: manipulation;
}
</style>
