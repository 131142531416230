const axios = require("axios").default;
const moment = require("moment");

const wpUrl = "https://sh-dev.maptivateapp.com.au/wp/";

const htmlDecode = function(input) {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

//import { proxyRefs } from "vue";

//import { _ } from "core-js";

//const useLocal = false;

Array.prototype.forEachAsync = async function(fn) {
  for (let t of this) {
    await fn(t);
  }
};

Array.prototype.forEachAsyncParallel = async function(fn) {
  await Promise.all(this.map(fn));
};

const calcClustering = features => {
  //features.forEach

  let multiMarkerList = {};
  let stackedMarkers = {};

  for (let index = 0; index < features.length; index++) {
    //let properties = features[index].properties;

    features[index].properties.stackIndex = false;
    features[index].properties.hasStacking = false;
    features[index].properties.hideForStacking = false;

    try {
      let geoIndex = features[index].properties.geoIndex;
      if (!multiMarkerList[geoIndex]) {
        multiMarkerList[geoIndex] = [];
      } else {
        //item already on this point
        features[index].properties.hideForStacking = true;
      }

      multiMarkerList[geoIndex].push(index);
      if (multiMarkerList[geoIndex].length > 1) {
        stackedMarkers[geoIndex] = multiMarkerList[geoIndex];
      }
    } catch (error) {
      //do nothing
    }
  }

  for (let index = 0; index < features.length; index++) {
    let geoIndex = features[index].properties.geoIndex;

    if (stackedMarkers[geoIndex]) {
      //for manual stack, if title is longer than x, add '...'

      features[index].geometry.coordinates[0] = roundLongLat(
        features[index].properties.Latitude
      );
      features[index].geometry.coordinates[1] = roundLongLat(
        features[index].properties.Longitude
      );

      let stackedIndexLabel = stackedMarkers[geoIndex]
        .map(n => {
          //return n + 1;

          return features[n].properties.indexLabel;
        })
        .join(", ");

      stackedIndexLabel =
        stackedIndexLabel.length > 9
          ? stackedIndexLabel.substring(0, 9) + "..."
          : stackedIndexLabel;

      features[index].properties.stackedIndexLabel = stackedIndexLabel;

      features[index].properties.hasStacking = true;
      features[index].properties.stackIndex = multiMarkerList[geoIndex].indexOf(
        index
      );

      let stackIndex = features[index].properties.stackIndex;

      const offsetAmount = 1;
      const enableMarkerOffsetPatter = false;

      features[index].properties.stackOffset = [
        -1 * offsetAmount * features[index].properties.stackIndex,
        -1 * offsetAmount * features[index].properties.stackIndex
      ];

      //stacking offset stuff;
      if (enableMarkerOffsetPatter) {
        features[index].properties.stackOffset =
          stackIndex === 0
            ? [0, 10]
            : stackIndex === 1
            ? [-5, -5]
            : stackIndex === 2
            ? [5, -5]
            : [0, 10];
      }
    }
  }

  return { features, stackedMarkers };
};

const cycleThroughPosts = async function(axios, wpUrl, postType) {
  var allResults = [];

  let page = 1;
  let hasError = false;
  let wpTotalpages = 100;

  let lastResult;
  do {
    let results = await axios.get(
      `${wpUrl}/wp-json/wp/v2/${postType.postId}/?per_page=99&page=${page}`
    );
    if (results.status !== 200) {
      hasError = true;
      break;
    }
    if (!(results?.headers && results.headers["x-wp-totalpages"])) {
      hasError = true;
      break;
    }
    if (results.data && results.data.length) {
      allResults = [...allResults, ...results.data];
    }
    wpTotalpages = results.headers["x-wp-totalpages"];
    page++;
    lastResult = results;
  } while (page <= wpTotalpages && !hasError);
  lastResult.data = allResults;
  return lastResult;
};

const state = {
  post: {},
  taxonomy: {},
  wpdata: {}
};

const targets = [
  {
    postId: "markers",
    taxonomies: ["markersection"],
    sectionTaxonomies: "markersection",
    geoData: true
  } /*,

  {
    postId: "setup",
    taxonomies: [],
    isSetupObject: true
  } ,
  {
    postId: "pages",
    taxonomies: [],
  },*/
];

const devideby = 10000000000;

const roundLongLat = a => {
  //return a;
  return Math.round(a * devideby) / devideby;
};

const genGeoIndex = (a, b) => {
  a = Math.round(a * devideby) / devideby;
  b = Math.round(b * devideby) / devideby;
  return "geo" + a + b;
};

const genGeoJsonAndConfig = async ({ post, taxonomy }, targets) => {
  try {
    let maptivateSetupObject = {};
    let output = {};
    let geoJsonFeatures = [];
    let sectionArray = [];
    let markerImages = {};
    let setTaxonomySetOrder = [];

    //let sectionTypes = ["main_marker", "artwork", "signage", "other"];
    let geoJsonByType = {};
    targets.forEach(({ postId, sectionTaxonomies, geoData, isSetupObject }) => {
      if (isSetupObject === true && post[postId] && post[postId][0]) {
        maptivateSetupObject = post[postId][0];
      }

      if (geoData === true && post[postId]) {
        let setPost = post[postId];
        let setTaxonomy = taxonomy[sectionTaxonomies];

        setTaxonomySetOrder = [
          ...setTaxonomySetOrder,
          ...setTaxonomy.map(row => row.slug)
        ];

        let setTaxonomyObj = setTaxonomy.reduce(function(map, obj) {
          map[obj.id] = obj;
          //map[obj.slug] = obj;
          return map;
        }, {});
        sectionArray = [...sectionArray, ...setTaxonomy];
        setPost.forEach(post => {
          let { markersection } = post;
          let markerSectionObj = false;
          if (
            setTaxonomyObj &&
            markersection[0] &&
            setTaxonomyObj[markersection[0]]
          ) {
            markerSectionObj = setTaxonomyObj[markersection[0]];
          } else {
            //markerSectionObj = setTaxonomy[0];
            console.error("error, post has no section", {
              post,
              markersection,
              setTaxonomyObj
            });
          }

          if (
            post.cust_location &&
            post.cust_location[1] &&
            post.cust_location[1] &&
            post.status == "publish"
          ) {
            let section_type = markerSectionObj?.acf?.type?.value;
            let section_id = markerSectionObj?.id;
            let section_slug = markerSectionObj?.slug;
            let section_prfixCode = markerSectionObj?.acf?.PrifixCode?.value
              ? markerSectionObj?.acf?.PrifixCode?.value
              : section_id;

            let icon_obj = null; //{id, url, title}

            let { id, href, title, thumb, marker, marker_lg } =
              post?.featured_image_obj?.href && post?.featured_image_obj?.id
                ? post.featured_image_obj
                : markerSectionObj.featured_image_obj;

            let scale_factor = false;
            let isArtwork = false;
            if (section_type === "artwork") {
              isArtwork = true;
            }

            if (!isNaN(post?.acf?.MO_artwork_scale_factor?.value)) {
              try {
                scale_factor = parseFloat(
                  post?.acf?.MO_artwork_scale_factor?.value
                );
                if (scale_factor === 1 || scale_factor === 0) {
                  scale_factor = false;
                }
              } catch (error) {
                //do nothing;
              }
            }

            if (id && href) {
              icon_obj = { id, href, title, thumb, marker };
              if (isArtwork === true) {
                icon_obj.isArtwork = true;
                icon_obj.marker_lg = marker_lg;
                icon_obj.section_slug = section_slug;
              }
              if (scale_factor !== false) {
                icon_obj.scale_factor = scale_factor;
              }
              markerImages[id] = icon_obj;
            }

            if (post.media_urls.length && post.featured_media) {
              //post.featured_media //id
              post.media_urls = post.media_urls.filter(row => {
                return row.ID != post.featured_media;
              });
            }

            let customData = {};

            for (let key in post.acf) {
              if (post.acf[key] && key !== "location") {
                customData[key] = post.acf[key];
              }
            }

            let structuredData = processAcfData(customData);

            //post.cust_location[1] = roundLongLat(post.cust_location[1]);
            //post.cust_location[0] = roundLongLat(post.cust_location[0]);

            let geoIndex = genGeoIndex(
              post.cust_location[1],
              post.cust_location[0]
            );

            let Site_Marker_Number_numPart = customData?.M_ST_Site_ID?.value
              ? customData.M_ST_Site_ID?.value
              : "post_" + post.id;

            let Site_Marker_Number_Label = Site_Marker_Number_numPart;
            let Site_Marker_Number =
              section_prfixCode + "__" + Site_Marker_Number_numPart;

            let Point_Name = htmlDecode(
              post?.title?.rendered ? post.title.rendered : post.title
            );

            let markerObj = {
              // feature for Mapbox DC
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [post.cust_location[1], post.cust_location[0]]
              },

              properties: {
                stackIndex: Site_Marker_Number,
                hasStacking: false,
                hideForStacking: false,
                geoIndex: geoIndex,
                hideMakerButMakeClickable: "false",

                Point_Name,
                Site_Marker_Number,
                Site_Marker_Number_Label,

                Longitude: post.cust_location[0],
                Latitude: post.cust_location[1],

                post_id: post.id,

                slug: post.slug,
                content: post.content,
                media:
                  post.media_urls && post.media_urls.length
                    ? post.media_urls
                    : null,
                //customData,
                structuredData,
                date: post.date,
                modified: post.modified,
                section_type,
                section_id,
                section_slug,
                post_type_slug: postId,
                icon_obj,
                icon_id: icon_obj?.id ? icon_obj?.id : false
              }
            };

            if (!geoJsonByType["geoJson_" + section_type]) {
              geoJsonByType["geoJson_" + section_type] = {
                type: "FeatureCollection",
                features: []
              };
            }
            geoJsonFeatures.push(markerObj);
            geoJsonByType["geoJson_" + section_type].features.push(markerObj);
          }
        });
      }
    });

    let sectionObj = {};
    let categoryLookup = {};

    sectionArray.forEach(row => {
      let {
        id,
        count,
        description,
        name,
        slug,
        taxonomy,
        featured_image_obj,
        acf
      } = row;

      let SectionLink = acf?.SectionLink?.value;
      let colour = acf?.colour?.value;
      let type = acf?.type?.value;

      let options = processAcfTaxonomy(acf);

      if (count) {
        categoryLookup[slug] = {
          id,
          key: slug,
          count,
          description,
          title: name,
          name,
          section_slug: slug,
          taxonomy,
          featured_image_obj,
          SectionLink: SectionLink,
          color: colour,
          section_type: type,
          padding: "15px",
          visible: type != "artwork" && type != "other" ? true : false,
          options
        };

        sectionObj[slug] = {
          id,
          key: slug,
          count,
          description,
          title: name,
          name,
          section_slug: slug,
          taxonomy,
          featured_image_obj,
          SectionLink: SectionLink,
          color: colour,
          section_type: type,
          padding: "15px",
          options
        };
      }
    });

    //sort by cat order !!!
    let rawFeatues = geoJsonByType.geoJson_main_marker.features;
    let features = [];

    setTaxonomySetOrder.forEach(orderKey => {
      if (categoryLookup[orderKey]) {
        let key = categoryLookup[orderKey].key;

        let cat = categoryLookup[key];
        features = [
          ...features,
          ...rawFeatues.filter(
            point => point.properties.section_slug == cat.section_slug
          )
        ];
      }
    });

    let temp = calcClustering(features);

    let stackedMarkers = temp.stackedMarkers;
    features = temp.features;

    geoJsonByType.geoJson_main_marker.features = features;

    output = {
      maptivateSetupObject,
      stackedMarkers,
      sections: sectionObj,
      markerImages,
      /*geoJson: {
        type: "FeatureCollection",
        features: geoJsonFeatures,
      },*/
      ...geoJsonByType,
      categoryLookup,
      categoryOrder: setTaxonomySetOrder
    };

    return output;
  } catch (error) {
    console.error("errorC", error);
  }
};

const getters = {};
const actions = {
  async wpData_initData({ commit } /*, { vm }*/) {
    var post = {};
    var taxonomy = {};

    await targets.forEachAsyncParallel(async postType => {
      try {
        //let results = await vm.axios.get(`${wpUrl}/wp-json/wp/v2/${postType.postId}/?per_page=10`);

        let results = await cycleThroughPosts(axios, wpUrl, postType);
        if (results && results.data) post[postType.postId] = results.data;
      } catch (e) {
        console.error("errorA", e);
      }

      if (postType.taxonomies && postType.taxonomies.length) {
        await postType.taxonomies.forEachAsyncParallel(async taxType => {
          try {
            let results = await axios.get(
              `${wpUrl}/wp-json/wp/v2/${taxType}?per_page=99`
            );
            if (results && results.data) taxonomy[taxType] = results.data;
          } catch (e) {
            console.error("errorB", e);
          }
        });
      }
    });

    let output = await genGeoJsonAndConfig({ post, taxonomy }, targets);

    commit("SET_WP_DATA", output);
  }
};

const mutations = {
  SET_WP_DATA(state, newValue) {
    state.wpdata = newValue;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

const processAcfData = function(acfData) {
  //this function takes data from wordpress custom fields, using the prefix of the lug to idntify what type of field that data is.

  //prfix: {details}, using slug as prfix.

  //toAdd::

  const labelDisplaySuffixes = {
    "##H_INFO": "hideInSiteInfo", //hide site info
    "##H_POP": "hideInPopUp", //only for subtitles
    "##S_POP": "showInPopUp" //show in popup;
  };

  const custDataTypes = {
    M_SM_: { sectionKey: "socialmedia", type: "url" },
    M_SC_: { sectionKey: "soundcloud", type: "link" },
    M_L_: { sectionKey: "links", type: "url" }, //point links as list
    M_SPECIAL_LINK_: { sectionKey: "link", type: "url" }, //single titled link where label is the title
    M_B_LINK_: { sectionKey: "buttonlink", type: "url" }, //single titled link where label is the title
    M_VL_: { sectionKey: "videolink", type: "url" },

    M_ST_: { sectionKey: "subtitle", type: "subtitle" },

    M_NO_: { sectionKey: "note", subSection: "text", type: "note" },
    M_TA_: { sectionKey: "note", subSection: "textarea", type: "note" },

    M_NU_: { sectionKey: "value", subSection: "number", type: "value" },
    M_C_S_: { sectionKey: "value", subSection: "choiceSingle", type: "value" },
    M_C_M_: { sectionKey: "value", subSection: "choiceMulti", type: "array" },
    M_C_YN_: {
      sectionKey: "value",
      subSection: "choiceTrueFalse",
      type: "value"
    },

    M_FLYOBJ_: { sectionKey: "flytopoint", type: "flyto" },
    M_GO_PHO_SPHERE_: { sectionKey: "photosphere", type: "url" },

    M_DATE_: { sectionKey: "datetime", subSection: "date", type: "date" },
    M_DATETIME_: {
      sectionKey: "datetime",
      subSection: "datetime",
      type: "date"
    },
    M_TIME_: { sectionKey: "datetime", subSection: "time", type: "date" }
  };

  let structuredData = {};
  let order = 0;

  let sectionCounter = {};

  for (var key in acfData) {
    let acfObj = acfData[key];

    let { label, value } = acfObj;
    let displayShowHideKeys = {};
    if (label) {
      //this code looks at codes in the lables to decided if they should show or hide in popup/site info
      for (var prefix in labelDisplaySuffixes) {
        let labelKeyMod = labelDisplaySuffixes[prefix];
        if (label.includes(prefix)) {
          displayShowHideKeys[labelKeyMod] = true;
        }
      }

      try {
        //if acf label contains ""_LABEL_"" use the part after the word as actual label.
        try {
          if (label.includes("##")) {
            label = label.split("##")[0];
          }
        } catch (error) {
          //do nothing;
        }
        if (label.includes("_LABEL_")) {
          label = label
            .split("_LABEL_")[1]
            .replace(/_/g, " ")
            .replace(/-/g, " ")
            .trim();
          if (!label) {
            label = false;
          }
        }
      } catch (error) {
        //label = acfObj.label;
      }

      for (var prfix in custDataTypes) {
        if (key.startsWith(prfix)) {
          let { sectionKey, subSection, type } = custDataTypes[prfix];

          if (!structuredData[sectionKey]) {
            structuredData[sectionKey] = {};
          }

          sectionCounter[sectionKey] = sectionCounter[sectionKey]
            ? sectionCounter[sectionKey]
            : 0;
          sectionCounter[sectionKey]++;

          structuredData[sectionKey][key] = {
            label,
            sectionKey,
            type,
            order,
            key,
            sectionOrder: sectionCounter[sectionKey],
            ...displayShowHideKeys
          };

          if (subSection) {
            structuredData[sectionKey][key].subSection = subSection;
          }

          if (subSection === "choiceTrueFalse") {
            structuredData[sectionKey][key]["value"] =
              value === true ? "Yes" : "No";
          } else if (sectionKey === "value" && type === "array") {
            structuredData[sectionKey][key]["values"] = value;
            structuredData[sectionKey][key]["value"] = value.join(", ");
          } else if (sectionKey === "datetime") {
            //date or time or datetime
            structuredData[sectionKey][key]["value"] = value;
            let temp;
            if (subSection === "datetime") {
              temp = moment(value, "YYYY-MM-DD H:m:s");
              structuredData[sectionKey][key]["value"] = temp.format(
                "Do MMM YYYY h:m a"
              );
            }
            if (subSection === "date") {
              temp = moment(value, "YYYYMMDD");
              structuredData[sectionKey][key]["value"] = temp.format(
                "Do MMM YYYY"
              );
            }
            if (subSection === "time") {
              temp = moment(value, "H:m:s");
              structuredData[sectionKey][key]["value"] = temp.format("h:m a");
            }
            if (temp) {
              structuredData[sectionKey][key]["date"] = temp.toDate();
            }
          } else if (typeof value === "object") {
            structuredData[sectionKey][key] = {
              ...structuredData[sectionKey][key],
              ...value
            };
          } else if (sectionKey == "socialmedia") {
            let socialBrands = [
              "instagram",
              "facebook",
              "twitter",
              "youtube",
              "tiktok",
              "linkedin",
              "pinterest"
            ];

            let title = "generic";
            //let search = "instagram";
            socialBrands.forEach(search => {
              if (
                value.trim() &&
                title == "generic" &&
                value
                  .trim()
                  .toLowerCase()
                  .includes(search)
              ) {
                title = search;
              }
            });

            if (value.trim() && value.toLowerCase().includes("http")) {
              structuredData[sectionKey][key]["title"] = title;
              structuredData[sectionKey][key]["url"] = value.trim();
            } else {
              delete structuredData[sectionKey][key];
            }
          } else {
            structuredData[sectionKey][key]["value"] = value;
          }

          order++;
        }
      }
    }
  }

  for (var sectionKey in structuredData) {
    let sectionSet = structuredData[sectionKey];
    for (var fieldKey in sectionSet) {
      if (sectionCounter[sectionKey]) {
        structuredData[sectionKey][fieldKey].sectionCount =
          sectionCounter[sectionKey];
      }
    }
  }

  return structuredData;
};

const processAcfTaxonomy = acfData => {
  let options = {
    imageSettings: {},
    layerOptions: {
      topLevel: {},
      paint: {},
      layout: {}
    }
  };

  const validKeyPrfixes = ["MLO_", "MI_"];

  for (var key in acfData) {
    let issue = false;

    let isValid = validKeyPrfixes.filter(prefix => key.includes(prefix));
    let acfObj = acfData[key];

    let { label, value } = acfObj;

    if (isValid.length && (value || value === 0)) {
      //hasPrefix and a value;

      if (key.includes("#json_obj#")) {
        try {
          value = JSON.parse(value);
        } catch (error) {
          issue = true;
          console.error("issue with JSON.parse(value)", {
            error,
            key,
            label,
            value
          });
        }
      }

      if (key.includes("#num#")) {
        try {
          value = parseFloat(value);
        } catch (error) {
          issue = true;
          console.error("issue with parseFloat(value)", {
            error,
            key,
            label,
            value
          });
        }
      }

      if (issue === false && (value || value === 0)) {
        if (key.startsWith("MLO_") && key.includes("#core#_")) {
          let optionKey = key.split("#core#_")[1];
          options.layerOptions.topLevel[optionKey] = value;
        } else if (key.startsWith("MLO_") && key.includes("#layout#_")) {
          let optionKey = key.split("#layout#_")[1];
          options.layerOptions.layout[optionKey] = value;
        } else if (key.startsWith("MLO_") && key.includes("#paint#_")) {
          let optionKey = key.split("#paint#_")[1];
          options.layerOptions.paint[optionKey] = value;
        } else if (key === "MI_#num#_section_artwork_scale_factor") {
          options.imageSettings.section_artwork_scale_factor = value;
        }
      }
    }
  }

  return options;
};
