<template>
  <div>
    <v-btn
      v-if="!href"
      depressed
      :color="color"
      :width="width"
      class="my-1 mx-2 white--text oneLineEclipsText"
      @click="
        clickfunction();
        triggerEvent();
      "
    >
      <v-icon left>{{ icon }}</v-icon>
      <span :style="{ 'max-width': textWidth }">
        {{ title }}
      </span>
    </v-btn>

    <v-btn
      v-if="href"
      depressed
      :color="color"
      :width="width"
      class="my-1 mx-2 white--text oneLineEclipsText"
      :href="href"
      :target="target"
    >
      <v-icon left>{{ icon }}</v-icon>
      <span :style="{ 'max-width': textWidth }">
        {{ title }}
      </span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "maptivateInfoButton",
  components: {},

  props: {
    Site_Marker_Number: String,
    butType: String,
    isPopup: Boolean,

    width: String,
    color: String,
    icon: String,
    title: String,
    href: String,
    target: String,
    clickfunction: Function
  },
  methods: {
    triggerEvent() {
      let event_name = `${this.butType}_Click`;
      let event_params = `${this.Site_Marker_Number}_${this.title}_${
        this.isPopup ? "P" : "S"
      }`;

      window.mvAnalyticEvent("ui", event_name, event_params, 1);
    }
  },
  computed: {
    textWidth() {
      try {
        let textWidth = parseInt(this.width.replace("px", "")) - 30;
        return `${textWidth}px`;
      } catch (e) {
        return this.width;
      }
    }
  }
};
</script>

<style scoped>
.v-btn.oneLineEclipsText span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
