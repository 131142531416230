import { mapState } from "vuex";
import _ from "lodash";
import { eventBus } from "../../../main";

export default {
  data: () => ({
    bounce_animationInterval: null,
    bounce_index: null,
    bounce_targetLayers: null,
    bounce_betweenTime: 45,
    bounce_heightFrame: null,
    bounce_timeline: [12, 7, 0.01, 4, 6, 5, 4, 2, 0.1]
  }),

  computed: {
    ...mapState({
      //global:
      appSettings: state => state.global.appSettings
    })
  },

  methods: {
    bounce_clearInterval() {
      clearInterval(this.bounce_animationInterval);
      this.bounce_updateStyle(-1, 0);
    },
    bounce_updateStyle(index, bounce_heightFrame) {
      if (this.bounce_timeline[bounce_heightFrame]) {
        let offset = this.bounce_timeline[bounce_heightFrame] * -1 * 0.3;
        this.bounce_targetLayers.forEach(layerId => {
          if (!layerId.includes("_clustered_")) {
            try {
              if (layerId && this.map && this.map.getLayer(layerId))
                this.map.setLayoutProperty(layerId, "icon-offset", [
                  "case",
                  ["==", ["get", "index"], index],
                  ["literal", [0, offset * 10]],
                  ["literal", [0, 0]]
                ]);

              this.map.setLayoutProperty(layerId, "text-offset", [
                "case",
                ["==", ["get", "index"], index],
                ["literal", [0, 0.4 + offset * 0.35]],
                ["literal", [0, 0.4]]
              ]);
            } catch (error) {
              //do nothing
            }
          }
        });
      }
    },
    bounce_bouncMarker() {
      this.bounce_heightFrame = 0;

      try {
        this.bounce_clearInterval();
      } catch (error) {
        //do nothing;
      }

      this.bounce_targetLayers = _.values(this.pointLayerIds).filter(layer =>
        layer.includes("places_")
      );
      this.bounce_animationInterval = setInterval(() => {
        this.bounce_updateStyle(this.bounce_index, this.bounce_heightFrame);

        this.bounce_heightFrame++;

        if (!this.bounce_timeline[this.bounce_heightFrame]) {
          this.bounce_clearInterval();
        }
      }, this.bounce_betweenTime);
    },
    bounce_bouncMarker_debounced: _.debounce(function() {
      this.bounce_bouncMarker();
    }, 200)
    /* 
       setInterval(()=> {
        value = !value;


        if (value) {
          _map.setLayoutProperty(layerId_unclustered_lables, "text-offset", [0, 0.4]);
          _map.setLayoutProperty(layerId_unclustered, "icon-offset", [0, 10]);
        } else {
          _map.setLayoutProperty(layerId_unclustered, "icon-offset", [0, 0]);
          _map.setLayoutProperty(
            layerId_unclustered_lables,
            "text-offset",
            [
              "case",
              ["==", ["get", "post_id"], 248],
              ["literal", [0, 1]],
              ["literal", [0, 0.4]]
            ]
          );
        }
      }, 1000);  
      
      */
  },
  created() {},
  destroyed() {},
  mounted() {
    eventBus.$on("makerEffects_bouncMarker", index => {
      if (index) {
        this.bounce_index = index;
        this.bounce_bouncMarker_debounced();
      }
      //
    });
  }
};
