<template>
  <v-card elevation="4" v-if="ready">
    <v-card-title
      :class="appSettings.headingTextColor"
      v-if="false && mappedMenuItemsFilter.length"
      >has items</v-card-title
    >
    <v-list
      dense
      :max-height="$vuetify.breakpoint.mobile ? '230px' : '310px'"
      :class="$vuetify.breakpoint.mobile ? 'custScroller' : 'custScroller'"
      style="overflow-y:auto"
    >
      <template v-for="(item, itemIndex) in mappedMenuItemsFilter">
        <v-list-item
          :key="item.index"
          @click="
            loadMarkerByIdFromMenu(item.index);
            popupClicked(item);
          "
        >
          <v-list-item-content>
            <v-list-item-title class="grey--text font-weight-normal">
              <div
                class="pa-0  rounded-circle d-inline-block"
                :style="{
                  height: '12px',
                  width: '12px',
                  background:
                    item.section_slug && categoryLookup[item.section_slug]
                      ? categoryLookup[item.section_slug].color
                      : '',
                  border: '1px solid grey'
                }"
              ></div>

              &nbsp;&nbsp;&nbsp;&nbsp;
              <font
                v-show="
                  appSettings.showSiteNumbers && item.Site_Marker_Number_Label
                "
                >{{ item.Site_Marker_Number_Label }} -
              </font>
              {{ item.Point_Name
              }}<font v-if="categoryLookup[item.section_slug]">
                |
                {{ categoryLookup[item.section_slug].title }}</font
              ></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-divider
          :key="item.header"
          v-if="mappedMenuItemsFilter.length - 1 != itemIndex"
        ></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    ready: false,
    showCarousel: false
  }),

  props: {
    getAudioDetails: Function,
    loadMarkerByIdFromMenu: Function,
    appSettings: Object,
    categoryLookup: Object,
    mappedMenuItems: {
      required: true,
      type: Array
    },
    vuetifyCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    mappedMenuItemsFilter() {
      return this.mappedMenuItems;
    }
  },
  mounted() {
    this.$vuetify = this.vuetifyCopy;
    setTimeout(() => {
      this.ready = true;
      this.showCarousel = true;
    }, 10);
  },
  methods: {
    popupClicked(properties) {
      //window.gtag("event", "makerClick", properties.Site_Marker_Number);
      window.mvAnalyticEvent(
        "map",
        "makerClick",
        properties.Site_Marker_Number,
        false
      );
    }
  }
});
</script>

<style>
.mapboxgl-popup-content {
  box-shadow: none;
}
.galMenu,
.galMenu .mapboxgl-popup-content {
  background: transparent;
  padding: 0;
}

.galMenu {
  height: 90px;
}
.galMenu .mapboxgl-popup-content .v-card {
  min-height: 90px;
  width: 240px;
}

.custScroller::-webkit-scrollbar {
  width: 1em;
}

.custScroller::-webkit-scrollbar-track {
  xbox-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #f1f1f1;
}

.custScroller::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  outline: 1px solid #a1a1a1;
}

.pointPopupTooltip {
  margin-top: 10px;
}
.pointPopupTooltip .mapboxgl-popup-content {
  border-radius: 4px;
  padding: 2px 4px;
  text-align: center;
}

.pointPopupTooltip .mapboxgl-popup-tip {
  display: none;
}
</style>
