const getters = {};

const mutations = {
  SET_markerList(state, newValue) {
    state.markerList = newValue;
  },
  SET_geoJson_artwork(state, newValue) {
    state.geoJson_artwork = newValue;
  },
  SET_geoJson_signage(state, newValue) {
    state.geoJson_signage = newValue;
  },
  SET_markerImages(state, newValue) {
    state.markerImages = newValue;
  },
  SET_sections(state, newValue) {
    state.sections = newValue;
  },
  SET_categoryLookup(state, newValue) {
    state.categoryLookup = newValue;
  },
  SET_stackedMarkers(state, newValue) {
    state.stackedMarkers = newValue;
  },
  SET_markersScaleOpacityClass(state, newValue) {
    state.markersScaleOpacityClass = newValue;
  }
};

const actions = {
  async maker_SetmarkersScaleOpacityClass({ commit }, value) {
    commit("SET_markersScaleOpacityClass", value);
  },
  async markers_updateData({ commit }, wpData) {
    wpData.geoJson_main_marker.features.forEach((row, index) => {
      row.properties.index = index;
    });

    commit("SET_markerList", {
      type: "geojson",
      data: wpData.geoJson_main_marker
    });
    commit("SET_geoJson_artwork", {
      type: "geojson",
      data: wpData.geoJson_artwork
    });
    commit("SET_geoJson_signage", {
      type: "geojson",
      data: wpData.geoJson_signage
    });
    commit("SET_markerImages", wpData.markerImages);
    commit("SET_sections", wpData.sections);
    commit("SET_categoryLookup", wpData.categoryLookup);
    commit("SET_stackedMarkers", wpData.stackedMarkers);
  }
};

const state = {
  markerList: { type: "geojson", data: {} },
  geoJson_artwork: { type: "geojson", data: {} },
  geoJson_signage: { type: "geojson", data: {} },

  markerImages: {},
  sections: {},
  categoryLookup: {},
  stackedMarkers: {},
  markersScaleOpacityClass: "makerOpaciyClass10"
};

export default {
  state,
  getters,
  actions,
  mutations
};
