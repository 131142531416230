<template>
  <v-card elevation="6" x_shaped v-if="ready">
    <v-carousel
      eager
      v-if="properties.media && properties.media.length"
      hide-delimiters
      :show-arrows="properties.media.length > 1"
      :height="'180px'"
    >
      <v-carousel-item
        v-for="(item, i) in properties.media"
        :key="i"
        :src="item.src_thumb"
      ></v-carousel-item>
    </v-carousel>

    <div class="titleBox  secondary--text">
      <v-card-title key="" v-if="properties.Point_Name"
        >{{ properties.Point_Name }}
      </v-card-title>
    </div>

    <extraInfoBlocks
      :appSettings="appSettings"
      :media="properties.media"
      :Point_Name="properties.Point_Name"
      :Site_Marker_Number="properties.Site_Marker_Number"
      :structuredData="properties.structuredData"
      :functions="{ openYoutube, setSound, getAudioDetails, pointSet }"
      :isPopup="true"
    />

    <v-card-actions
      v-if="$vuetify.breakpoint.mdAndDown || !appSettings.autoOpenMoreInfo"
    >
      <v-btn
        :color="appSettings.dialogActionButtonColor"
        text
        @click="openSidebar(properties.index)"
        width="100%"
      >
        More Info
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn
        v-if="false"
        right
        :color="appSettings.dialogActionButtonColor"
        text
        @click="clearAllPopups"
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
//import { eventBus } from "../../main";

import Vue from "vue";

import extraInfoBlocks from "./../info/extraInfoBlocks.vue";

export default Vue.extend({
  components: {
    extraInfoBlocks
  },
  data: () => ({
    ready: false,
    showCarousel: false,
    media: [],
    width: "224px"
  }),

  props: {
    //goToPointByPointName: Function,
    getAudioDetails: Function,
    openYoutube: Function,
    setSound: Function,
    openSidebar: Function,
    clearAllPopups: Function,
    appSettings: Object,
    pointSet: Object,
    properties: {
      required: true,
      type: Object
    },
    vuetifyCopy: {
      required: true,
      type: Object
    },
    browserDetectCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    linkPoint() {
      var link = null;
      try {
        if (this.properties && this.properties.linkPoint) {
          link = this.properties.linkPoint;

          try {
            link = JSON.parse(link);
          } catch (error) {
            //do nothing;
          }
        }
      } catch (error) {
        //do nothing;
      }
      return link;
    }
  },
  mounted() {
    this.$vuetify = this.vuetifyCopy;
    if (this.browserDetectCopy) {
      this.$browserDetect = this.browserDetectCopy;
    }

    this.ready = true;
    this.showCarousel = true;

    setTimeout(() => {
      if (
        !this.$vuetify.breakpoint.mdAndDown &&
        this.appSettings.autoOpenMoreInfo
      ) {
        //not small!!!
        this.openSidebar(this.properties.index);
      }
    }, 500);
  },

  methods: {}
});
</script>

<style>
.mapboxgl-popup-content {
  box-shadow: none;
}
.galPopup,
.galPopup .mapboxgl-popup-content {
  background: transparent;
  padding: 0;
}

.galPopup {
  height: 270px;
}
.galPopup .mapboxgl-popup-content .v-card {
  min-height: 90px;
  width: 240px;
  max-width: 80vw;
}

.titleBox {
  xmargin-top: -6px;
  xmargin-bottom: -6px;
}

@media only screen and (max-width: 650px) {
  .galPopup .mapboxgl-popup-content {
    zoom: 75%;
  }

  .titleBox {
    margin-top: -12px;
    margin-bottom: -12px;
  }
  .audioBox {
    margin-bottom: -6px;
  }
}
</style>
